import _ from 'lodash';
import { DateTime, Duration, Settings } from 'luxon';
import { getWeekStartByLocale } from 'weekstart';
import { MeetyTimeUnit } from '../constants/enums';
import { ShopInfo } from '../types/shop-types';
import { TimezoneFullDto } from '../types/master-data-type';

export function getPreviousWeek(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).minus({ week: 1 });
}

export function getNextWeek(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).plus({ week: 1 });
}

export function getPreviousMonth(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).minus({ month: 1 });
}

export function getNextMonth(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).plus({ month: 1 });
}

export function getAllDaysInWeek(date: DateTime) {
  const days = new Array<DateTime>(date);
  const weekFirstDay = getWeekFirstDay(Settings.defaultLocale);

  let firstDay: DateTime;
  while ((firstDay = _.first(days)).weekday !== weekFirstDay) {
    days.unshift(getPreviousDate(firstDay));
  }

  let lastDay: DateTime;
  while ((lastDay = _.last(days)).weekday % 7 !== weekFirstDay - 1) {
    days.push(getNextDate(lastDay));
  }
  return days;
}

export function getHoursInDay(locale: string): string[] {
  const date = DateTime.local({ locale });
  return Array.from({ length: 24 }, (_, i) => i).map((hour) => {
    return date.set({ hour, minute: 0 }).toLocaleString(DateTime.TIME_SIMPLE);
  });
}

export function getUnixTimestamp(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

export function getStartOfToday(): DateTime {
  return DateTime.now().startOf('day');
}

export function formatTime(dateTime: DateTime, is24Hours: boolean): string {
  return dateTime.toFormat(is24Hours ? 'T' : 't');
}

export function getTimeStamp(dateTime: string): number {
  const duration = Duration.fromISOTime(dateTime);
  if (!duration.isValid) {
    return 0;
  }

  return DateTime.now().startOf('day').plus(duration).toSeconds();
}

export const getTimeZoneByOffset = (
  second: number,
  tz: 'UTC' | 'GMT' = 'UTC'
) => {
  const M = Math.abs(Math.floor(second / 60));
  const h = Math.floor(M / 60);
  const m = Math.floor(M % 60);
  // GMT+10:30
  const d = `${tz}${second > 0 ? '+' : '-'}${h < 10 ? `0${h}` : h}:${
    m < 10 ? `0${m}` : m
  }`;
  return d;
};

export const getMinute = (value: number, type: MeetyTimeUnit) => {
  const currentRate = getRate(type);
  return value * currentRate;
};

export const getValue = (minutes: number, type: MeetyTimeUnit) => {
  const currentRate = getRate(type);
  return minutes / currentRate;
};

export const getRate = (type: MeetyTimeUnit) => {
  switch (type) {
    case MeetyTimeUnit.Week:
      return 24 * 60 * 7;
    case MeetyTimeUnit.Month:
      return 24 * 60 * 30;
    case MeetyTimeUnit.Hour:
      return 60;
    case MeetyTimeUnit.Day:
      return 24 * 60;
    case MeetyTimeUnit.Minute:
      return 1;
  }
  return 1;
};

export const convertValue = (
  value: number,
  fromType: MeetyTimeUnit,
  toType: MeetyTimeUnit
) => {
  if (Number(value) == 0) {
    return 0;
  }
  const currentRate = getRate(fromType);
  const newRate = getRate(toType);
  if (currentRate != newRate) {
    return (Number(value) * currentRate) / newRate;
  }
  return Number(value);
};

export function getAllDaysInMonthWithSupplement(
  date: DateTime,
  zone: string
): DateTime[] {
  const year = date.year;
  const month = date.month;
  const daysInMonth = getAllDaysInMonth(year, month, zone);
  const weekFirstDay = getWeekFirstDay(Settings.defaultLocale);

  let firstDay: DateTime;
  while ((firstDay = daysInMonth[0]).weekday !== weekFirstDay) {
    daysInMonth.unshift(getPreviousDate(firstDay));
  }

  let lastDay: DateTime;
  while (
    (lastDay = daysInMonth[daysInMonth.length - 1]).weekday % 7 !==
    weekFirstDay - 1
  ) {
    daysInMonth.push(getNextDate(lastDay));
  }

  return daysInMonth;
}

export function getFirstAndLastDaysOfMonthWithSupplement(
  date: DateTime,
  zone: string
): [DateTime, DateTime] {
  const year = date.year;
  const month = date.month;
  const weekFirstDay = getWeekFirstDay(Settings.defaultLocale);

  let firstDay = getDate(year, month, 1, zone);
  while (firstDay.weekday !== weekFirstDay) {
    firstDay = getPreviousDate(firstDay);
  }

  let lastDay = getDate(year, month, date.daysInMonth ?? 30, zone);
  while (lastDay.weekday % 7 !== weekFirstDay - 1) {
    lastDay = getNextDate(lastDay);
  }

  return [firstDay.startOf('day'), lastDay.endOf('day')];
}

export function getFirstAndLastDaysOfMonthsWithSupplement(
  monthCount: number,
  date: DateTime,
  zone: string
): [DateTime, DateTime] {
  if (monthCount <= 1) {
    return getFirstAndLastDaysOfMonthWithSupplement(date, zone);
  } else {
    const [first] = getFirstAndLastDaysOfMonthWithSupplement(date, zone);
    const [, last] = getFirstAndLastDaysOfMonthWithSupplement(
      date.plus({ month: monthCount - 1 }),
      zone
    );
    return [first, last];
  }
}

export function getDate(
  year: number,
  month: number,
  day: number,
  zone: string
): DateTime {
  return DateTime.fromObject({ year, month, day }, { zone });
}

export function getPreviousDate(date: DateTime): DateTime {
  return date.minus({ day: 1 });
}

export function getNextDate(date: DateTime): DateTime {
  return date.plus({ day: 1 });
}

export function getAllDaysInMonth(
  year: number,
  month: number,
  zone: string
): DateTime[] {
  const date = getDate(year, month, 1, zone);
  return Array.from({ length: date.daysInMonth ?? 30 }, (_, i) =>
    getDate(year, month, i + 1, zone)
  );
}

export function getWeekFirstDay(locale?: string): number {
  const lc = locale ?? 'en';
  const intlLocale = new Intl.Locale(lc) as unknown as {
    getWeekInfo: () => { firstDay: number };
    weekInfo: { firstDay: number };
  };
  const { firstDay = 1 } = intlLocale.getWeekInfo
    ? intlLocale.getWeekInfo()
    : intlLocale.weekInfo
    ? intlLocale.weekInfo
    : { firstDay: [7, 1, 2, 3, 4, 5, 6][getWeekStartByLocale(lc)] };

  return firstDay;
}

export function isInDst(
  shopInfo: {
    timezoneInfos: TimezoneFullDto[];
    applyDst?: number;
    dstFrom?: number;
    dstTo?: number;
  },
  checkTime: number
): boolean {
  if (!shopInfo?.applyDst) {
    return false;
  }
  if (shopInfo?.timezoneInfos) {
    const tzs = shopInfo?.timezoneInfos?.filter(
      (obj) => obj.timeStart <= checkTime
    );
    if (tzs && tzs.length > 0) {
      tzs.sort((a, b) => b.timeStart - a.timeStart);
      return tzs[0].dst == '1';
    }
  }
  return false;
}

export function getInDstLength(
  shopInfo: {
    timezoneInfos: TimezoneFullDto[];
    applyDst?: number;
    dstFrom?: number;
    dstTo?: number;
    dstShiftSchedule?: number;
    dstLength?: number;
  },
  now: number
): number {
  const isDST = isInDst(shopInfo, now);
  return shopInfo?.applyDst && shopInfo?.dstShiftSchedule
    ? isDST
      ? shopInfo?.dstLength
      : 0
    : 0;
}
